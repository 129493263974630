import React from 'react';
import { Box, Toolbar, CssBaseline } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import ButtonAppBar from '../shared/AppBar';
import Footer from '../shared/Footer';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Fade from '@mui/material/Fade';

function ScrollTop(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 64, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
};


function ElevationScroll(props) {
    const { children, window } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
        background: trigger ? 'linear-gradient(90deg, #76aeee, #7b3aa2)' : 'transparent'
    });
}

export default function DefaultLayout({ children }) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <CssBaseline />
            <ElevationScroll>
                <ButtonAppBar />
            </ElevationScroll>
            <Toolbar id="back-to-top-anchor" />
            <Box component="main" sx={{ flexGrow: 1, width: '100%', overflow: 'hidden' }}>
                {/*<Container maxWidth='xl' sx={{*/}
                {/*    px: { xs: 0, sm: 0, md: 0 },*/}
                {/*}}>*/}
                    {children}
                {/*</Container>*/}
            </Box>
            <ScrollTop>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <Footer />
        </Box>
    );
}