import React from 'react';
import {Box, Grid, Typography, useTheme} from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";


const Footer = () => {
    const theme = useTheme();

    return (
      <Box sx={{
          // backgroundColor: theme.palette.secondary.main,
          background: 'linear-gradient(90deg, #76aeee, #7b3aa2)',
          color: theme.palette.common.white,
      }}>
          <Grid container sx={{ justifyContent: 'space-between', p: 1, alignItems: 'center' }}>
              <Grid item>
                  <Typography variant="body2">
                      &copy; 2024 LRND.ai
                  </Typography>
              </Grid>
              <Grid item>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                      {/*<FacebookIcon sx={{ color: '#fff', cursor: 'pointer' }} />*/}
                      {/*<TwitterIcon sx={{ color: '#fff', cursor: 'pointer' }} />*/}
                      {/*<InstagramIcon sx={{ color: '#fff', cursor: 'pointer' }} />*/}
                      <Box component='a' href={"https://www.linkedin.com/company/lrnd-ai/about/"} target="_blank" rel="noopener noreferrer">
                          <LinkedInIcon sx={{ color: theme.palette.common.white, cursor: 'pointer' }} />
                      </Box>

                  </Box>
              </Grid>
          </Grid>
      </Box>
    )
};

export default Footer;