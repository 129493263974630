import { createTheme } from '@mui/material/styles';


const themeOptions = {
    palette: {
        primary: {
            main: '#76aeee',
        },
        secondary: {
            main: '#7b3aa2',
        },
        common: {
            white: '#FFFFFF',
            black: "#000000"
        }
    },

    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        h1: {
            fontSize: '2.5rem',
            '@media (min-width:600px)': {
                fontSize: '3rem',
            },
            '@media (min-width:900px)': {
                fontSize: '3.5rem',
            },
        },
        h2: {
            fontSize: '2rem',
            '@media (min-width:600px)': {
                fontSize: '2.5rem',
            },
            '@media (min-width:900px)': {
                fontSize: '3rem',
            },
        },
        h3: {
            fontSize: '1.75rem',
            '@media (min-width:600px)': {
                fontSize: '2rem',
            },
            '@media (min-width:900px)': {
                fontSize: '2.25rem',
            },
        },
        h4: {
            fontSize: '1.5rem',
            '@media (min-width:600px)': {
                fontSize: '1.75rem',
            },
            '@media (min-width:900px)': {
                fontSize: '2rem',
            },
        },
        h5: {
            fontSize: '1.25rem',
            '@media (min-width:300px)': {
                fontSize: '0.85rem',
            },
            '@media (min-width:600px)': {
                fontSize: '1.2rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.5rem',
            },
        },
        h6: {
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.25rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.5rem',
            },
        },
        body1: {
            fontSize: '1rem',
            '@media (min-width:600px)': {
                fontSize: '1.1rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1.2rem',
            },
        },
        body2: {
            fontSize: '0.875rem',
            '@media (min-width:600px)': {
                fontSize: '0.95rem',
            },
            '@media (min-width:900px)': {
                fontSize: '1rem',
            },
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background: 'linear-gradient(90deg, rgb(118 174 238 / 7%) 0%, rgb(123 58 162 / 23%) 100%)'
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                containedPrimary: {
                    background: 'linear-gradient(135deg, #76aeee, #7b3aa2)',
                    borderRadius: '8px',
                    color: '#fff',
                    '&:hover': {
                        background: 'linear-gradient(135deg, #7b3aa2, #76aeee)',
                    },
                },
            },
        },
    }
};

const theme = createTheme(themeOptions);

export default theme;