import React from 'react';
import {Box, Dialog, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const YouFormModal = ({open, handleClose}) => {
    return (
        <Box sx={{ textAlign: 'center', padding: 8, backgroundColor: '#7F38A2', color: '#fff' }}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>
                    Secure Your Spot Now!
                    <Typography variant='body2' pt={1}>
                        Join our waiting list to gain early access at no cost and supercharge your productivity. Stay ahead of the curve with the latest tools and updates!
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <iframe
                        src="https://app.youform.com/forms/8z4awgfg"
                        title="Join Waitlist"
                        loading="lazy"
                        width="100%"
                        height="500"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        scrolling="yes"
                        style={{ border: 'none',  objectFit: 'contain'}}
                    ></iframe>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default YouFormModal;
