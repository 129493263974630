// import React from 'react';
// import { Box, Typography } from '@mui/material';
//
// const HighlightCard = ({ icon, title, description }) => {
//     return (
//         <Box
//             sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 alignItems: 'flex-start',
//                 textAlign: 'left',
//                 padding: 3,
//                 borderRadius: '8px',
//                 boxShadow: '0 0 50px rgba(123, 58, 162, 0.3)',
//                 backgroundColor: '#fff',
//                 marginBottom: 4,
//             }}
//         >
//             {/*<Box sx={{ fontSize: '3rem', color: '#7F38A2', marginBottom: 2 }}>*/}
//                 {icon}
//             {/*</Box>*/}
//             <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, marginTop: 1 }}>
//                 {title}
//             </Typography>
//             <Typography variant="body1" sx={{ color: '#666' }}>
//                 {description}
//             </Typography>
//         </Box>
//     );
// };
//
// export default HighlightCard;


import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const HighlightCard = ({ icon, title, description }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const variants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <Box
            ref={ref}
            component={motion.div}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={variants}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                textAlign: 'left',
                padding: 3,
                borderRadius: '8px',
                boxShadow: '0 0 50px rgba(123, 58, 162, 0.3)',
                backgroundColor: '#fff',
                marginBottom: 4,
            }}
        >
            {icon}
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: 1, marginTop: 1 }}>
                {title}
            </Typography>
            <Typography variant="body1" sx={{ color: '#666' }}>
                {description}
            </Typography>
        </Box>
    );
};

export default HighlightCard;
