import React from 'react';
import { Box, Typography } from '@mui/material';
import vision from '../../../../../assets/vision.jpeg'

const ValueProposition = () => {
    return (
        <Box
            sx={{
                textAlign: 'center',
                padding: 8,
                color: '#333',
                // backgroundImage: `url(${vision})`,
            }}
        >
            <Typography variant="h3" component="h2" sx={{ fontWeight: 'bold', mb: 4 }}>
                Manage, Understand and Succeed
            </Typography>
            <Typography variant="h5" sx={{ lineHeight: 1.5, mb: 4 }} >
                Our AI-powered knowledge management tool revolutionizes the way you handle academic materials.
                Balancing academic and personal life has never been easier. Experience personalized learning,
                seamless document retrieval, and intuitive interaction, all in one state-of-the-art assistant.
            </Typography>
            <Box
                component="img"
                src={vision} // Replace with the actual image path
                alt="Value Proposition Illustration"
                sx={{
                    maxWidth: '100%',
                    width: {xs: '100%', sm: '80%', lg: '50%'},
                    height: 'auto',
                    borderRadius: 4,
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    mt: 4,
                }}
            />
        </Box>
    );
};

export default ValueProposition;
