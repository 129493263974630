import React, {useState} from 'react';
import HeroSection from "./partials/HeroSection";
import Features from "./partials/Features";
import Canvas from "../../shared/Canvas";
import Highlights from "./partials/Highlights";
import BottomCTA from "./partials/BottomCTA";
import ValueProposition from "./partials/Vision";
import YouFormModal from "../../shared/Modal/YouformModal";


const HomePage = () => {
    const [open, setOpen] = useState(false);

    const handleModalClose = () => {
        setOpen(false);
    };

    const handleModalOpen = () => {
        setOpen(true);
    };
    return (
        <>
            <HeroSection handleClick={handleModalOpen}/>
            <Canvas />
            <ValueProposition/>
            <Canvas/>
            <Features/>
            <Canvas/>
            <Highlights/>
            <Canvas/>
            <BottomCTA handleClick={handleModalOpen}/>
            <YouFormModal open={open} handleClose={handleModalClose} />
        </>
    )
};


export default HomePage;

