import React from 'react';
import {
    Box,
    Typography,
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    useTheme
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const benefits = [
    "Enjoy personalized learning experiences.",
    "Benefit from tailored study aids and stress management tools.",
    "Effortlessly manage and retrieve all your learning materials in one place.",
    "Get automatic citations for source materials to boost academic credibility.",
    "Generate interactive study cards from handouts, presentations, and more."
];

const BottomCTA = ({ handleClick }) => {
    const theme = useTheme();
    return (
        <Box sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            pb: 4
        }}>
            <Box
                sx={{
                    textAlign: 'center',
                    padding: 8,
                }}
            >
                <Typography variant="h2" component="h1" sx={{ fontWeight: 'bold', mb: 4, lineHeight: 1.5 }}>
                    Secure Your Spot and Stay Ahead
                </Typography>
                <Typography variant="h5" sx={{ mb: 4, lineHeight: 1.5 }}>
                    Be among the first to:
                </Typography>
                <List sx={{ textAlign: 'left', display: 'inline-block', marginBottom: 4 }}>
                    {benefits.map((benefit, index) => (
                        <ListItem key={index}>
                            <ListItemIcon>
                                <CheckCircleIcon sx={{ color: '#fff' }} />
                            </ListItemIcon>
                            <ListItemText primary={benefit} />
                        </ListItem>
                    ))}
                </List>
                <Box
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick = {handleClick}
                        sx={{
                            fontSize: {sm: '1rem', xs: '1.5rem', md: '1.25rem'},
                        }}
                    >
                        Secure Your Spot
                    </Button>
                </Box>
            </Box>

        </Box>

    );
};

export default BottomCTA;

