import React from'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

//rotas
import {BrowserRouter, Routes, Route} from 'react-router-dom'

import { StyledEngineProvider } from "@mui/material";

import DefaultLayout from "./components/layout";
import theme from "./utils/theme";
import HomePage from "./components/modules/Home";
import ContactUs from "./components/modules/Contact";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
              <CssBaseline />
              <BrowserRouter>
                  <DefaultLayout>
                      <Routes>
                        <Route path='/' element={<HomePage />} />
                        <Route path='/contact' element={<ContactUs/>} />
                      </Routes>
                  </DefaultLayout>
              </BrowserRouter>
          </StyledEngineProvider>
      </ThemeProvider>

  );
}

export default App;