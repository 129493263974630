import React from 'react';
import {Box, Typography} from "@mui/material";
import FeatureItem from "../../../../shared/FeatureItem";

import featurev1 from "../../../../../assets/featurev1-2.jpeg";
import realTimeV3 from "../../../../../assets/realTimeV3.jpeg";
import speech2text from "../../../../../assets/speect2text.jpeg";
import studyCardV2 from "../../../../../assets/studyCardV2.jpeg";
import rag from "../../../../../assets/rag.jpeg";

const features = [
    {
        title: 'Automatic Content Organization',
        // description: 'Effortlessly organize your study materials into different modules, ensuring everything is neatly categorized and easily accessible.',
        description: 'Effortlessly organize your resources into modules for neat categorisation and easy access, ensuring everything is well-structured and readily available when you need it most, enhancing your study efficiency.',
        question: 'Tired of cluttered notes and disorganized study materials?',
        image: featurev1,
    },
    {
        title: 'Personalized Study Aids',
        // description: 'Create personalized study cards, generate notes, and take mock tests tailored to your learning style and curriculum, enhancing your study efficiency.',
        description: 'Create personalized study cards, generate notes, and take mock tests tailored to your learning style and curriculum. This approach ensures you get the most out of your study time, boosting efficiency and understanding.',
        question: 'Struggling with ineffective study methods?',
        image: studyCardV2,
    },
    // {
    //     title: 'Realtime Collaboration',
    //     description: 'Collaborate in real-time on notes with audio and video call integration, and maintain version history for seamless teamwork and communication.',
    //     image: realTimeV3, // Replace with the actual path
    // },
    {
        title: 'Summarization',
        // description: 'Get concise and accurate summaries of your saved documents, highlighting key concepts and arguments.',
        description: 'Get concise and accurate summaries of your saved documents, highlighting key concepts and arguments for easier study. This streamlined approach saves time and enhances comprehension, making your review process more efficient and effective.',
        question: 'Overwhelmed by too much reading?',
        image: realTimeV3,
    },
    {
        title: 'Speech To Text',
        // description: 'Convert audio lectures into structured notes with ease, allowing you to review content in both text and audio formats.',
        description: 'Convert audio lectures into structured notes effortlessly, allowing you to review and reinforce learning through both text and audio formats. This dual approach ensures a more comprehensive understanding and retention of material.',
        question: 'Struggling to keep up with fast-paced lectures?',
        image: speech2text,
    },
    {
        title: 'RAG (Read, Answer, Generate)',
        // description: 'Engage in conversations with a module to get detailed explanations, answer questions, and generate comprehensive study materials.',
        description: 'Engage in conversations with a module to receive detailed explanations, answer questions, and generate comprehensive study materials. This interactive approach enhances understanding and equips you with tailored resources for effective learning.',
        question:  'Struggling to grasp complex topics?',
        image: rag,
    },
];


const Features = () => {
    return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', textAlign: 'center', padding: 4, mt: 4 }}>
                <Typography variant="h3" component="h2" sx={{ marginBottom: 4, fontWeight: 'bold' }}>
                    Discover How <span style={{ background: "-webkit-linear-gradient(135deg, #76aeee, #7b3aa2)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent", fontWeight: '700' }}>LRND</span> <br /> Enhances Your Learning
                </Typography>
                {/* Add the feature items here */}
                {features.map((feature, index) => (
                    <FeatureItem
                        key={index}
                        title={feature.title}
                        description={feature.description}
                        image={feature.image}
                        question={feature.question}
                        reverse={index % 2 !== 0}
                    />
                ))}
            </Box>
    )
};

export default Features;