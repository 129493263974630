import React from 'react';
import {Box, Grid, Typography} from '@mui/material';
import HighlightCard from '../../../../shared/HighlightCard';

// Importing Material Icons
import BrainIcon from '@mui/icons-material/Memory';
import InterfaceIcon from '@mui/icons-material/DesktopWindows';
import IntegrationIcon from '@mui/icons-material/IntegrationInstructions';
import CollaborationIcon from '@mui/icons-material/Groups';
import SupportIcon from '@mui/icons-material/HeadsetMic';

const highlights = [
    {
        icon: <BrainIcon color='secondary'/>,
        title: 'Innovative AI Technology',
        description: 'Our cutting-edge AI technology personalizes your learning experience, providing customized study aids and insights tailored to your unique needs.',
    },
    {
        icon: <InterfaceIcon color='secondary' />,
        title: 'User-Friendly Interface',
        description: 'With a clean, intuitive interface, LRND makes it easy to organize, access, and review your study materials, enhancing your productivity.',
    },
    {
        icon: <IntegrationIcon color='secondary' />,
        title: 'Seamless Integration',
        description: 'Integrate notes, slides, PDFs, and textbooks seamlessly, ensuring all your study materials are in one place and easily accessible.',
    },
    // {
    //     icon: <CollaborationIcon color='secondary' />,
    //     title: 'Real-Time Collaboration',
    //     description: 'Collaborate with peers in real-time using our audio and video call integration, with version history to track changes and ensure smooth teamwork.',
    // },
    {
        icon: <CollaborationIcon color='secondary' />,
        title: 'Credible References',
        description: 'Automatically get the citations for any question you ask with source material retrieved from your library, saving you time.',
    },
    {
        icon: <SupportIcon color='secondary' />,
        title: 'Comprehensive Support',
        description: 'Access 24/7 support and a rich knowledge base to help you navigate and make the most of LRND’s features.',
    },
];

const Highlights = () => {
    return (
        <Box sx={{ textAlign: 'center', padding: 4, mt: 4}}>
            <Typography variant="h3" component="h2" sx={{ marginBottom: 4, fontWeight: 'bold' }}>
                Explore Why <span style={{ color: '#7F38A2' }}>LRND</span> Stands Out
            </Typography>
            <Grid container spacing={4} justifyContent="center" >
                {highlights.map((highlight, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <HighlightCard
                            icon={highlight.icon}
                            title={highlight.title}
                            description={highlight.description}
                        />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default Highlights;
