// import React from 'react';
// import { Box, Typography } from '@mui/material';
//
// const ContactUs = () => {
//     return (
//         <Box sx={{
//             padding: 4,
//             minHeight: '100vh',
//             display: 'flex',
//             mt: 4,
//             mb: 16
//         }}>
//             <Box sx={{ p: 1, textAlign: 'left' }}>
//                 <Typography variant="h3" component="h1" sx={{ fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
//                     Contact Us
//                 </Typography>
//                 <Box sx={{ textAlign: 'left', mb: 4 }}>
//                     <Typography variant="h5" sx={{ lineHeight: 1.5, maxWidth: '800px', margin: '0 auto' }}>
//                         We are on a mission to empower university students to achieve academic excellence while maintaining a healthy balance between their academic and personal lives.
//                         By providing a state-of-the-art AI assistant, we aim to deliver personalized learning experiences, foster seamless collaboration, and simplify knowledge management.
//                         Our goal is to transform the educational landscape, making high-quality, tailored academic support accessible and affordable for all students.
//                     </Typography>
//                 </Box>
//             </Box>
//
//             <Box
//                 sx={{
//                     flexGrow: 1,
//                 }}
//             >
//                 <iframe
//                     src="https://app.youform.com/forms/wmz6c0th"
//                     title="Contact Us"
//                     // loading="lazy"
//                     width="100%"
//                     height="600"
//                     frameBorder="0"
//                     marginHeight="0"
//                     marginWidth="0"
//                     style={{
//                         borderRadius: '4px',
//                         boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
//                     }}
//                 ></iframe>
//             </Box>
//         </Box>
//     );
// };
//
// export default ContactUs;

import React from 'react';
import { Box, Typography } from '@mui/material';

import contact from '../../../assets/contactUsImage.jpeg'

const ContactUs = () => {
    return (
        <>
            <Box
                sx={{
                    height: '60vh',
                    backgroundImage: `url(${contact})`, // Replace with the correct path to your image
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    },
                }}
            >
            </Box>


            <Typography
                variant="h3"
                component="h1"
                sx={{
                    fontWeight: 'bold',
                    paddingTop: 8,
                    textAlign: 'center'
                }}
            >
                Contact Us
            </Typography>

            <Box sx={{
                padding: 4,
                minHeight: '100vh',
                display: 'flex',
                mb: 16,
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Box sx={{ p: 1, textAlign: 'left', maxWidth: '800px' }}>
                    <Typography variant="h5" sx={{ lineHeight: 1.5, mb: 4 }}>
                        We are on a mission to empower university students to achieve academic excellence while maintaining a healthy balance between their academic and personal lives.
                        By providing a state-of-the-art AI assistant, we aim to deliver personalized learning experiences, foster seamless collaboration, and simplify knowledge management.
                        Our goal is to transform the educational landscape, making high-quality, tailored academic support accessible and affordable for all students.
                    </Typography>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        width: '100%',
                        maxWidth: '800px',
                    }}
                >
                    <iframe
                        src="https://app.youform.com/forms/wmz6c0th"
                        title="Contact Us"
                        // loading="lazy"
                        width="100%"
                        height="600"
                        frameBorder="0"
                        marginHeight="0"
                        marginWidth="0"
                        style={{
                            borderRadius: '4px',
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        }}
                    ></iframe>
                </Box>
            </Box>
        </>
    );
};

export default ContactUs;
