import React from 'react';
import {Box, Typography, useMediaQuery, useTheme} from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const FeatureItem = ({ title, description, image, question, reverse }) => {
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const variants = {
        hidden: { opacity: 0, x: reverse ? 100 : -100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    };

    const theme = useTheme();

    const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box
            ref={ref}
            component={motion.div}
            initial="hidden"
            animate={inView ? 'visible' : 'hidden'}
            variants={variants}
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: reverse ? 'row-reverse' : 'row' },
                alignItems: 'center',
                marginBottom: 4,
                marginTop: 4,
            }}
        >
            <Box sx={{ flex: 1, padding: 2, textAlign: 'left' }}>
                <Typography variant="h4" sx={{
                    marginBottom: 2,
                    background: "-webkit-linear-gradient(135deg, #76aeee, #7b3aa2)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent" }}>
                    {title}
                </Typography>
                <Typography variant="h5">
                    <span style={{fontWeight: 700}} >{question} </span>{description}
                </Typography>
            </Box>
            <Box sx={{ flex: 1, padding: 2,
            }}>
                <img src={image} alt={title} style={{
                    width: isMobileOrTablet ? '100%' : '70%',
                    borderRadius: '10px',
                    // boxShadow: '0 4px 8px rgba(118, 174, 235, 0.3), 0 6px 20px rgba(123, 58, 162, 0.3)'
                    boxShadow: '0 0 50px rgba(123, 58, 162, 0.3)'
                }} />
            </Box>
        </Box>
    );
};

export default FeatureItem;
