import * as React from 'react';
import {useNavigate} from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import logo from '../../../assets/logo.jpeg'
import {Button, useTheme} from "@mui/material";


const ButtonAppBar = ({elevation, background}) => {
    const navigate = useNavigate();
    const theme = useTheme();
    return (
            <AppBar elevation={elevation} sx={{ background: background, color: theme.palette.common.black, pb: '8px' }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1, display: 'flex', mt: 1, alignItems: 'center' }} onClick= {() => { navigate(`/`)}}>
                        <img src={logo} width='48px' alt="LRND Logo"/>
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                ml: 1,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'Nunito, sans-serif',
                                fontWeight: 800,
                                // letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LRND
                        </Typography>
                    </Box>
                    <Button sx={{
                        color: theme.palette.common.black,
                        background: theme.palette.common.white,
                        borderRadius: 8,
                        textTransform: 'none',
                        pl: 2,
                        pr: 2,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    }}
                            onClick={() => {
                                navigate(`/contact`)
                            }}
                    >Contact Us</Button>
                </Toolbar>
            </AppBar>
    );
};

export default ButtonAppBar;
