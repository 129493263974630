import React, { useEffect } from 'react';

import { useCanvasContext } from '../../hooks/useCanvas';
import useResponsiveSize from '../../hooks/useResponsiveSize';
import WaveObj from '../../utils/wave';

const Wave = () => {
    const { context } = useCanvasContext();
    const { width } = useResponsiveSize();
    const height = 600;
    let frequency = 0.013;

    // Updated colors to match the gradient theme
    const waves = {
        frontWave: new WaveObj([0.0211, 0.028, 0.015], 'rgba(118, 174, 235, 0.3)'),
        backWave: new WaveObj([0.0122, 0.018, 0.005], 'rgba(123, 58, 162, 0.3)'),
    };

    const render = () => {
        context?.clearRect(0, 0, width, height);
        Object.entries(waves).forEach(([, wave]) => {
            wave.draw(context, width, height, frequency);
        });
        frequency += 0.013;
        requestAnimationFrame(render);
    };

    useEffect(() => {
        if (context) render();
    }, [context]);

    return null;
};

export default Wave;
