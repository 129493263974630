import React from 'react';
import {Box, Typography, Button, Grid, Toolbar} from '@mui/material';


import heroImageNoBackground  from '../../../../../assets/heroImageNoBackground.png'

const Index = ({ handleClick }) => {

    return (
            // height: '100vh'
            <Box sx={{  width: '100%', p: 3 }} >
                <Toolbar/>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={12} md={7}>
                        <Box sx={{ margin: '0 auto', display: 'flex', flexDirection: 'column', maxWidth: {md: '700px', xs: '100%'}}}>
                            <Typography variant="h2" component="h1" color="black" sx={{ fontWeight: 'bold', mb: 4 }}>
                                Transform Your Learning Experience with
                                <span style={{
                                    fontWeight: '700',
                                    background: "-webkit-linear-gradient(135deg, #76aeee, #7b3aa2)",
                                    WebkitBackgroundClip: "text",
                                    WebkitTextFillColor: "transparent",
                                    fontFamily: 'Nunito, sans-serif',
                                }}> LRND</span>
                            </Typography>
                            <Typography variant="h1" component="h2" color="black" gutterBottom sx={{  mb: 4 }}>
                                Powered By AI.
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: {
                                    xs: 'column',
                                    sm: 'row'
                                }
                            }}>
                                {/*<TextField label="Email" variant="outlined" size='small' sx={{*/}
                                {/*    width: {sm: '50%', md: '50%', xs: '100%'}*/}
                                {/*}}/>*/}

                                <Button
                                    variant="contained"
                                    onClick={handleClick}
                                    sx={{
                                        fontSize: {sm: '1rem', xs: '1.5rem', md: '1.25rem'},
                                        textTransform: 'none',
                                        fontWeight: '600',
                                        marginLeft: {
                                            xs: 'auto',
                                            sm: '16px'
                                        },
                                        marginTop: {
                                            sm: 'auto',
                                            xs: '16px'
                                        },
                                        width: {sm: '50%', md: '50%', xs: '100%'}
                                    }}
                                >
                                    Secure Your Spot
                                </Button>
                            </Box>

                        </Box>
                    </Grid>
                    <Grid item xs={12} md={5} >
                        <Box>
                            <img
                                src={heroImageNoBackground} // Replace with actual path
                                alt="Students using LRND"
                                style={{
                                    marginTop: 16,
                                    borderRadius: '10px',
                                    maxWidth: '100%',
                                    height: 'auto',
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </Box>
    );
};

export default Index;
